import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

let bearer = {
    request: function (req: AxiosRequestConfig, rawToken: string) {
        let token = JSON.parse(rawToken);

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token
        // @ts-ignore
        this.drivers.http.setHeaders.call(this, req, {
            Authorization: 'Bearer ' + token.access_token
        });
    },
    response: function (res: AxiosResponse) {
        return res.data.isOk ? JSON.stringify(res.data) : null;
    }
};

export default bearer;