import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home                                               from '../views/Home.vue'
import NotFound                                         from '../views/NotFound.vue'
import Forbidden                                        from '../views/Forbidden.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta     : {auth: true}
  },
  {
    path: '/auth/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/auth/LoginComponent.vue`)),
    meta     : {auth: false}
  },
  {
    path: '/auth/logout',
    name: 'logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/components/auth/LogoutComponent.vue'),
    meta     : {auth: true}
  },
  {
    path: '/sizes',
    name: 'sizes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/banner-size/BannerSizeList.vue`)),
    meta     : {auth: {roles: 'Banner_Administrator'}}
  },
  {
    path: '/sizes/add',
    name: 'sizes-form',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/banner-size/BannerSizeCreate.vue`)),
    meta     : {auth: {roles: 'Banner_Administrator'}}
  },
  {
    path: '/sites',
    name: 'sites',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/banner-site/BannerSiteList.vue`)),
    meta     : {auth: {roles: 'Banner_Administrator'}}
  },
  {
    path: '/sites/add',
    name: 'sites-form',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/banner-site/BannerSiteCreate.vue`)),
    meta     : {auth: {roles: 'Banner_Administrator'}}
  },
  {
    path: '/banner-groups',
    name: 'banner-groups',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/banner/BannerGroupList.vue`)),
    meta     : {auth: {roles: 'Banner_Administrator'}}
  },
  {
    path: '/banner-groups/edit/:requestId',
    name: 'banner-group-edit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/banner/BannerGroupEdit.vue`)),
    meta     : {auth: {roles: 'Banner_Administrator'}}
  },
  {
    path: '/banner-groups/add',
    name: 'banner-group-form',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import((`@/components/banner/BannerGroupCreate.vue`)),
    meta     : {auth: {roles: 'Banner_Administrator'}}
  },
  {
    path     : "/403",
    component: Forbidden
  },
  {
    path     : "/:catchAll(.*)",
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
