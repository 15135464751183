<template>
  <Header/>
  <div class="uk-container">
    <router-view v-slot="{ Component }">
      <component :is="Component"/>
    </router-view>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Header            from '@/components/Header.vue'; // @ is an alias to /src

export default defineComponent({
  components: {
    Header,
  },
});
</script>

<style lang="scss">
//@import './../public/style/fonts.scss';


.uk-button-danger {
  background-color: red !important;
  text-transform: none !important;
  border-radius: 38px !important;

  &.outline {
    background-color: white !important;
    color: red !important;
    border-color: red !important;
  }
}

.uk-button-default {
  background: #FAFAFA !important;
  border: 1px solid #B4B4B4 !important;
  box-sizing: border-box !important;
  border-radius: 55px !important;
  padding: 0 14px !important;
  text-transform: none !important;
}

</style>
