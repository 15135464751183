<template>
  <nav class="uk-container uk-margin-medium-bottom" uk-navbar>
    <div class="uk-navbar-left">
      <ul class="uk-navbar-nav">
        <li class="uk-active">
          <a href="/">
            <img src="/img/logo.svg" alt="">
          </a>
        </li>
        <li v-if="isAuth">
          <router-link :to="{name: 'banner-groups'}">Баннеры</router-link>
        </li>
        <li v-if="isAuth">
          <router-link :to="{name: 'sizes'}">Размеры баннеров</router-link>
        </li>
        <li v-if="isAuth">
          <router-link :to="{name: 'sites'}">Сайты</router-link>
        </li>
      </ul>
    </div>

    <div class="uk-navbar-right" v-if="isAuth">
      <ul class="uk-navbar-nav">
        <li>
          <router-link to="/auth/logout">Выйти</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name    : 'Header',
  props   : {
    msg: String,
  },
  computed: {
    isAuth(): boolean {
      // @ts-ignore
      return this.$auth.check();
    }
  }
});
</script>
