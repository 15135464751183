
import {defineComponent} from 'vue';

export default defineComponent({
  name    : 'Header',
  props   : {
    msg: String,
  },
  computed: {
    isAuth(): boolean {
      // @ts-ignore
      return this.$auth.check();
    }
  }
});
