// @ts-ignore
import {createAuth}          from '@websanova/vue-auth/src/v3.js';
// @ts-ignore
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
// @ts-ignore
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import axios                 from "axios";
import router                from '../router';
import driverAuthBearer      from "../auth/drivers/auth/Bearer";


let auth = createAuth({
    plugins: {
        http  : axios,
        router: router
    },
    drivers: {
        http  : driverHttpAxios,
        auth  : driverAuthBearer,
        router: driverRouterVueRouter,
    },
    options: {
        rolesKey         : 'scopes',
        authRedirect     : {path: '/auth/login'},
        forbiddenRedirect: {path: '/403'},
        notFoundRedirect : {path: '/404'},
        stores           : ['storage'],

        // Http

        loginData    : {
            url         : process.env.VUE_APP_API_URL + '/api/v1/auth/login',
            method      : 'POST',
            redirect    : '/',
            fetchUser   : true,
            staySignedIn: true
        },
        logoutData   : {
            redirect   : '/',
            makeRequest: false
        },
        fetchData    : {url: process.env.VUE_APP_API_URL + '/api/v1/auth/user', method: 'GET', enabled: true},
        refreshData  : {
            url     : process.env.VUE_APP_API_URL + '/api/v1/auth/refresh',
            method  : 'POST',
            enabled : false,
            interval: 30,
        },
        parseUserData: (res: object) => res || {}
    }
});


auth.__proto__.refresh = function () { // Подмена на свой обработчик обновления токена.
    this.options.refreshData.data = {
        refresh_token: JSON.parse(auth.token()).refresh_token
    }
    return auth.drivers.http.http.call(auth, this.options.refreshData);
}

export default auth;